<template>

  <div v-if="!is_loading" class="acitivity-timeline acitivity-main">
    <template v-if="journeyList.length > 0">
      <template v-if="mode === 'list'">

        <div class="mb-4">
          <b-button @click="see_in_map = !see_in_map" variant="soft-success" class="w-100">
            {{ see_in_map ? 'See the list' : 'See in map' }}
          </b-button>
        </div>

        <div v-if="see_in_map" style="height:900px; width: 100%; border-radius: 10px" class="overflow-hidden">

          <l-map ef="map" v-model:zoom="map.zoom"
                 :center="mapPositions.length > 0 ? mapPositions[mapPositions.length - 1].position : [0, 0]">
            <l-tile-layer
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                layer-type="base"
                name="OpenStreetMap"
            ></l-tile-layer>
            <l-marker
                v-for="(marker, index) in mapPositions"
                :key="index"
                :lat-lng="marker.position"
            ></l-marker>
          </l-map>
        </div>
        <div v-else>
          <div
              v-for="item in journeyList"
              :key="item"
              class="acitivity-item d-flex pb-3 cursor-pointer"
              @mouseenter="hovered_dislocation = item"
              @mouseleave="hovered_dislocation = {}"
              v-b-tooltip.hover
              title="Click to delete"
              @click="confirmDislocationDelete(item)"
          >
            <div class="flex-shrink-0 acitivity-avatar">
              <div
                  class="avatar-title rounded-circle"
                  :class="{
                'bg-soft-success text-success': hovered_dislocation.id !== item.id,
                'bg-danger text-light': hovered_dislocation.id === item.id,
              }"
              >
                <i :class="{
                  'ri-map-pin-time-line':  hovered_dislocation.id !== item.id,
                  'bx bx-trash-alt':  hovered_dislocation.id === item.id,
                }"></i>
              </div>
            </div>
            <div class="flex-grow-1 ms-3">
              <h6 class="mb-1 lh-base" :class="{
                'text-dark': hovered_dislocation.id !== item.id,
                'text-danger': hovered_dislocation.id === item.id,
              }">
                <strong>{{ item.operation_station_name }}</strong>
                -
                <strong>{{ item.distance }} km</strong>
              </h6>
              <p class="text-muted mb-1">
                {{ item.operation }}
              </p>
              <small class="mb-0 text-muted fw-semibold mb-4">
                {{ formatDate(item.operation_date) }}
              </small>
            </div>
          </div>
        </div>

      </template>
      <template v-else-if="mode === 'delete'">
        <h5 class="mb-3">Are you sure you want to delete this dislocation item ?</h5>

        <label class="form-label text-dark d-flex justify-content-between flex-wrap">
          <strong class="text-danger">{{ selected_dislocation.operation_station_name }} -
            {{ selected_dislocation.distance }} km</strong>
          <span class="d-block">
          {{ selected_dislocation.operation }}
          </span>
        </label>

        <form @submit.prevent="deleteDislocation()">
          <input v-model="dislocation_confirmation" class="form-control mb-2" type="text" placeholder="YES">
          <span>
          Type
          <span class="text-danger">
          <strong>YES</strong>
          </span>
          to confirm
        </span>


          <div style="height:300px; width: 100%; border-radius: 10px" class="mt-3 overflow-hidden">
            <l-map ef="map" v-model:zoom="map.zoom_when_deleting"
                   :center="[getSelectedDislocationLat(), getSelectedDislocationLng()]">
              <l-tile-layer
                  url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                  layer-type="base"
                  name="OpenStreetMap"
              ></l-tile-layer>
              <l-marker
                  v-for="(marker, index) in [{position: [getSelectedDislocationLat(), getSelectedDislocationLng()]}]"
                  :key="index"
                  :lat-lng="marker.position"
              ></l-marker>
            </l-map>
          </div>

          <div class="d-flex gap-2 mt-4">
            <b-button type="button" variant="light" class="w-100" @click="switchToListMode()">
              Back to List
            </b-button>
            <b-button
                :disabled="dislocation_confirmation !== 'YES'"
                type="submit" variant="danger" class="w-100">
              Confirm & Delete
            </b-button>
          </div>
        </form>

      </template>
    </template>
    <template v-else>
      <NoData>No Dislocation Found</NoData>
    </template>
  </div>

  <div v-else class="text-center py-5">
    <b-spinner variant="primary"></b-spinner>
    <h5 class="mb-0">
      <label class="d-block form-label mt-3 mb-0">Loading..</label>
    </h5>
  </div>

  <!-- SHOW ALL BUTTON -->
  <div class="d-flex justify-content-center text-center pt-2" v-if="limit < 100 && mode === 'list' && !see_in_map">
    <b-button variant="outline-primary" class="w-100" @click="getDislocationList(0, 100)">
      Show All
    </b-button>
  </div>

  <!-- PAGINATION STARTS HERE -->
  <div class="d-flex justify-content-center pt-3" v-if="limit >= 100 && data_count > 100 && mode === 'list' && !see_in_map">
    <nav aria-label="Page navigation example">
      <ul class="pagination mb-0">
        <li class="page-item"
            :class="{'disabled' : current_page === 1}"
            @click="goPage((current_page - 1) * limit - 100, limit, current_page - 1)"
        >
          <a class="page-link" aria-label="Previous">
            <i class="mdi mdi-chevron-left"></i>
          </a>
        </li>
        <li class="page-item cursor-pointer"
            v-for="page in pages"
            :key="`page_${page}_index`"
            :class="{'active' : page === current_page}"
            @click="goPage((page - 1) * limit, limit, page)"
        >
          <a class="page-link">
            {{ page }}
          </a>
        </li>
        <li class="page-item"
            :class="{'disabled' : current_page === pages}"
            @click="goPage((current_page + 1) * limit - 100, limit, current_page + 1)"
        >
          <a class="page-link" aria-label="Next">
            <i class="mdi mdi-chevron-right"></i>
          </a>
        </li>
      </ul>
    </nav>
  </div>

</template>

<script>
import axios from "axios";
import NoData from "@/components/custom/NoData.vue";
import Swal from "sweetalert2";
import moment from "moment";

import "leaflet/dist/leaflet.css";
import {LMap, LTileLayer, LMarker} from "@vue-leaflet/vue-leaflet";

export default {
  emits: ['deleteDislocation'],
  data() {
    return {
      journeyList: [],
      is_loading: true,
      data_count: 0,
      offset: 0,
      limit: 10,
      current_page: 1,

      hovered_dislocation: {},
      selected_dislocation: {},
      mode: 'list',
      see_in_map: false,
      dislocation_confirmation: '',

      map: {
        zoom: 5,
        zoom_when_deleting: 12
      }
    };
  },
  props: {
    wagon: {
      type: Object,
      required: true
    }
  },
  computed: {
    pages() {
      return Math.ceil(this.data_count / this.limit);
    },
    mapPositions() {
      return this.journeyList.map(i => (i.operation_station || [])).map(a => {
        return {
          position: [(a.latitude || 0), (a.longitude || 0)]
        }
      })
    }
  },
  components: {
    NoData,
    LMap, LTileLayer, LMarker
  },
  methods: {
    async getDislocationList(offset, limit) {
      if (offset || offset === 0) this.offset = offset;
      if (limit) this.limit = limit;

      try {
        this.is_loading = true;
        let response = await axios.get(
            `/train/dislocation/wagon/${this.$route.params.train_slug}/${this.wagon.name}/`, {
              params: {
                limit: this.see_in_map ? 10000000 : this.limit,
                offset: this.see_in_map ? 0 : this.offset
              }
            }
        );
        this.data_count = response.data.count;
        this.journeyList = response.data.results;
      } catch {
        console.log("error");
      }
      this.is_loading = false;
    },
    async goPage(offset, limit, page) {
      if (page < 1 || page > this.pages) return;
      this.current_page = page;
      await this.getDislocationList(offset, limit);
    },
    formatDate(date) {
      if (date) return moment(date).format('lll');
      return '-'
    },


    switchToListMode() {
      this.mode = 'list'
      this.dislocation_confirmation = ''
      this.selected_dislocation = {}
    },
    confirmDislocationDelete(dislocation) {
      this.selected_dislocation = dislocation
      this.mode = 'delete'
    },
    async deleteDislocation() {
      if (!this.selected_dislocation && !this.selected_dislocation.id) {
        alert("No dislocation was selected")
        return
      }
      if (this.dislocation_confirmation !== 'YES') return;
      try {
        await axios.delete(`/train/wagon/dislocation/delete/${this.selected_dislocation.id}/`)
        this.mode = 'list'
        this.selected_dislocation = {}
        await this.getDislocationList()
        await Swal.fire({
          icon: 'success',
          title: 'Dislocation deleted successfully',
          showConfirmButton: false,
          timer: 2000
        })
      } catch {
        await Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Something went wrong!',
        })
      }
    },

    getSelectedDislocationLat() {
      if (this.selected_dislocation && this.selected_dislocation.operation_station) {
        return this.selected_dislocation.operation_station.latitude || 0
      }
      return 0
    },
    getSelectedDislocationLng() {
      if (this.selected_dislocation && this.selected_dislocation.operation_station) {
        return this.selected_dislocation.operation_station.longitude || 0
      }
      return 0
    },
  },
  watch: {
    wagon: {
      handler: function () {
        this.mode = 'list'
        this.selected_dislocation = {}
        this.see_in_map = false
        this.getDislocationList()
      },
      deep: true,
      immediate: true
    },
    see_in_map(newValue) {
      if (newValue && newValue === true) {
        this.getDislocationList()
      }
    }
  }
};
</script>
