<script>
import axios from "axios"
import Swal from "sweetalert2";
import moment from "moment";

export default {
  name: "SingleWagonComments",
  props: {
    wagon: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      comment: {
        content: '',
      },
      is_sending_a_comment: false,
      is_loading_comments: false,
      comments: []
    }
  },
  computed: {
    formIsValid() {
      return this.comment.content.trim().length > 0
    }
  },
  methods: {
    async getWagonComments() {
      this.is_loading_comments = true
      try {
        let response = await axios.get(`/comment/list/`, {
          params: {
            train_wagon_id: this.wagon.id
          }
        })
        this.is_loading_comments = false
        this.comments = response.data.results.map(comment => {
          return {
            ...comment,
            mode: 'read',
          }
        })
      } catch {
        this.is_loading_comments = false
        alert("Couldn't get wagon comments")
      }
    },
    async sendComment() {
      let Toast = Swal.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: false,
        didOpen: (toast) => {
          toast.addEventListener('mouseenter', this.$swal.stopTimer)
          toast.addEventListener('mouseleave', this.$swal.resumeTimer)
        }
      })
      this.is_sending_a_comment = true
      try {
        await axios.post('/comment/', {
          content: this.comment.content,
          train_wagon_id: this.wagon.id,
          notify_by_mail: false
        })
        this.comment = {
          content: '',
        }
        this.is_sending_a_comment = false
        await this.getWagonComments()
        await Toast.fire({
          icon: 'success',
          title: 'Comment sent successfully',
        })
      } catch {
        this.is_sending_a_comment = false
        await Toast.fire({
          icon: 'error',
          title: 'Couldn\'t send comment, please try again later',
        })
      }
    },
    async saveComment(comment) {
      if (!comment.content.trim().length > 0) return
      comment.is_saving = true
      let Toast = Swal.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: false,
        didOpen: (toast) => {
          toast.addEventListener('mouseenter', this.$swal.stopTimer)
          toast.addEventListener('mouseleave', this.$swal.resumeTimer)
        }
      })
      try {
        await axios.put(`/comment/update/${comment.id}/`, {
          content: comment.content,
          train_wagon_id: this.wagon.id,
          notify_by_mail: false
        })
        comment.is_saving = false
        await Toast.fire({
          icon: 'success',
          title: 'Comment saved successfully',
        })
        await this.getWagonComments()
      } catch {
        comment.is_saving = false
        await Toast.fire({
          icon: 'error',
          title: 'Couldn\'t save comment, please try again later',
        })
      }
    },
    formatDate(date) {
      if (!date) return ''
      return moment(date).format('lll')
    }
  },
  watch: {
    wagon: {
      handler: function () {
        this.getWagonComments()
      },
      deep: true
    }
  }
}
</script>

<template>
  <div class="offcanvas offcanvas-end" tabindex="-1" id="SingleWagonCommentsCanvas"
       aria-labelledby="SingleWagonCommentsCanvasLabel">
    <div class="offcanvas-header">
      <h5 id="SingleWagonCommentsLabel" v-if="wagon && wagon.name">
        Wagon: {{ wagon.name }}
      </h5>
      <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
    </div>
    <div class="offcanvas-body pt-2">
      <form @submit.prevent="sendComment()" class="d-flex align-items-end mb-4 gap-3">
        <div class="w-100">
          <textarea v-model="comment.content" rows="5" placeholder="Comment.."
                    class="form-control"></textarea>
        </div>
        <BButton size="sm" v-b-tooltip.hover title="Send" :disabled="!formIsValid || is_sending_a_comment"
                 variant="primary" class="waves-effect waves-light"
                 type="submit"
        >
          <i class="mdi mdi-send"></i>
        </BButton>
      </form>
      <div>
        <h5 class="mb-4">Comments</h5>
        <div v-if="!is_loading_comments">
          <div v-if="comments.length > 0">
            <template v-for="comment in comments" :key="`train_comment_${comment.id}`">
              <div class="d-flex mb-2 w-100">
                <div class="avatar-xs flex-shrink-0 me-3">
                  <div class="avatar-title bg-soft-primary text-primary rounded-circle text-uppercase">
                    {{ (comment.created_by.username || comment.created_by.first_name).charAt(0) }}
                  </div>
                </div>
                <div class="w-100">
                  <div class="flex-grow-1 mb-2">
                    <div class="d-flex align-items-center justify-content-between gap-3">
                      <h5 class="fs-13 mb-0">
                        <a class="text-body d-block" href="#">
                          {{ comment.created_by ? comment.created_by.username || comment.created_by.first_name : '' }}
                        </a>
                      </h5>
                      <small v-b-tooltip.hover title="Last modified date">{{ formatDate(comment.modified) }}</small>
                    </div>
                  </div>
                  <div class="flex-shrink-0 w-100">
                    <p v-if="comment.mode === 'read'" class="mb-3">
                      {{ comment.content }}
                      <i @click="comment.mode = 'edit'"
                         class="mdi mdi-pencil c_icon_hoverable fs-5 ms-2"></i>
                    </p>
                    <div v-else-if="comment.mode === 'edit'"
                         class="d-flex align-items-end justify-content-between gap-3 mb-3">
                    <textarea v-model="comment.content" class="form-control form-control-sm" rows="4"
                              placeholder="Comment..."></textarea>
                      <b-button
                          :disabled="!comment.content.trim().length > 0 || comment.is_saving"
                          @click="saveComment(comment)" variant="primary" size="sm">Save
                      </b-button>
                    </div>
                  </div>
                </div>
              </div>
            </template>
          </div>
          <div v-else>
            <p class="text-muted">No comments yet</p>
          </div>
        </div>
        <div v-else>
          <b-spinner></b-spinner>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>

</style>