<script>
import {defineComponent} from "vue";
import Swal from "sweetalert2";
import axios from "axios";
import {SimpleBar} from "simplebar-vue3";

export default defineComponent({
  name: "UploadJourneyTab",
  emits: ["update"],
  data() {
    return {
      files: [],
      step: "step1",
      loading_step1: false,
      loading_step2: false,
      wagon_dislocation: [],
      wagons: [],
    };
  },
  components: {
    SimpleBar,
  },
  methods: {
    async onFileChange(e) {
      let file = e.target.files[0];
      if (file && this.isExcelFile(file)) {
        this.files = [file];
        return;
      }
      this.files = [];
    },

    deleteFile() {
      try {
        document.getElementById("train_dislocation_file").value = "";
        this.files = [];
      } catch {
        console.log("error");
      }
    },

    isExcelFile(file) {
      let is_valid =
          file.type ===
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
      if (is_valid === false) {
        const Toast = Swal.mixin({
          toast: true,
          position: "top",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
        });
        Toast.fire({
          icon: "warning",
          title: "Only excel files are allowed",
        });
        return false;
      } else {
        return true;
      }
    },

    async uploadExcel() {
      const Toast = Swal.mixin({
        toast: true,
        position: "top",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", Swal.stopTimer);
          toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
      });

      this.loading_step1 = true;
      const formData = new FormData();
      formData.append("file", this.files[0]);
      try {
        let response = await axios({
          url: `/train/parse-train-wagon-dislocation-xlsx/${this.$route.params.train_slug}/`,
          method: "post",
          data: formData,
          headers: {"Content-Type": "multipart/form-data"},
        });
        this.wagon_dislocation = response.data.wagon_dislocation;
        this.wagons = response.data.wagons.map((wagon) => {
          return {
            ...wagon,
            is_selected: true,
          };
        });
        this.step = "step2";
      } catch {
        await Toast.fire({
          icon: "warning",
          title: "Something went wrong, please check your file and train name",
        });
      }
      this.loading_step1 = false;
    },

    async confirmExcel() {
      this.loading_step2 = true;
      const Toast = Swal.mixin({
        toast: true,
        position: "top",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", Swal.stopTimer);
          toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
      });
      let wagons_selected = this.wagons.filter((wagon) => wagon.is_selected);
      let wagons_unselected = this.wagons.filter((wagon) => !wagon.is_selected);
      try {
        await axios.post(
            `/train/process-train-wagon-dislocation-xlsx/${this.$route.params.train_slug}/`,
            {
              wagons:
                  wagons_unselected.length === 0
                      ? []
                      : wagons_selected.map((wagon) => wagon.id),
              wagon_dislocation: this.wagon_dislocation,
            }
        );
        this.step = "step1";
        this.deleteFile();
        this.wagons = [];
        this.wagon_dislocation = [];
        this.loading_step1 = false;
        this.loading_step2 = false;
        try {
          this.$emit("update");
        } catch (e) {
          console.log(e);
        }
        await Toast.fire({
          icon: "success",
          title: "Excel successfully uploaded",
        });
      } catch {
        this.loading_step2 = false;
        await Toast.fire({
          icon: "warning",
          title: "Something went wrong, please try again",
        });
      }
    },
  },
  computed: {
    canUploadExcelProcess() {
      return true;
    },
    train_slug() {
      return this.$route.params.train_slug;
    },
  },
  watch: {
    excel_wagons() {
      console.log("change");
    },
  },
});
</script>

<template>
  <div v-show="step === 'step1'">
    <b-card>
      <div>
        <h6 class="mb-3">Upload Dislocation</h6>
        <input
            id="train_dislocation_file"
            @change="onFileChange"
            type="file"
            class="form-control"
            placeholder="Upload train dislocation excel"
            :disabled="loading_step1"
        />
      </div>
      <ul class="list-unstyled mb-0 mt-3" v-if="files.length > 0">
        <li>
          <div
              class="border rounded"
              v-for="(file, index) of files"
              :key="index"
          >
            <div class="d-flex p-2 align-items-center">
              <div class="flex-grow-1">
                <div class="pt-1">
                  <h5 class="fs-14 mb-1">
                    {{ file.name }}
                  </h5>
                  <p class="fs-13 text-muted mb-0">
                    <strong> {{ (file.size / 1000000).toFixed(2) }} </strong> MB
                  </p>
                </div>
              </div>
              <div class="flex-shrink-0 ms-3">
                <button
                    v-if="canUploadExcelProcess && !loading_step1"
                    @click="uploadExcel"
                    class="btn btn-sm btn-success"
                    :disabled="loading_step1"
                >
                  Start uploading
                </button>
                <button
                    v-if="loading_step1"
                    type="button"
                    class="btn btn-sm btn-success btn-load"
                >
                  <span class="d-flex align-items-center">
                    <span class="flex-grow-1 me-2"> Uploading </span>
                    <span class="spinner-grow flex-shrink-0" role="status">
                      <span class="visually-hidden">Uploading</span>
                    </span>
                  </span>
                </button>

                <button
                    @click="deleteFile"
                    class="btn btn-sm btn-danger ms-2"
                    :disabled="loading_step1"
                >
                  Delete
                </button>
              </div>
            </div>
          </div>
        </li>
      </ul>
    </b-card>
  </div>
  <div v-show="step === 'step2'">
    <b-card v-if="wagon_dislocation.length > 0">
      <div class="mb-3">
        <label class="form-label text-dark d-block mb-3">
          Wagons ({{ wagons.length }})
        </label>

        <div class="hstack gap-2 flex-wrap">
          <template
              v-for="(wagon, index) in wagons"
              :key="`wagon_${wagon.name}_${index + 1}`"
          >
            <input
                type="checkbox"
                class="btn-check"
                :id="`check_wagon_${wagon.name}_${index + 1}`"
                v-model="wagon.is_selected"
                :checked="wagon.is_selected"
            />
            <label
                class="btn btn-sm btn-outline-primary"
                :for="`check_wagon_${wagon.name}_${index + 1}`"
            >{{ wagon.name }}</label
            >
          </template>
        </div>
      </div>
      <label class="form-label text-dark d-block mb-3">
        Wagon Dislocation ({{ wagon_dislocation.length }} rows)
      </label>
      <SimpleBar data-simplebar style="max-height: 600px" class="mb-3">
        <div class="table-responsive">
          <table class="table">
            <thead>
            <tr class="align-middle">
              <template
                  v-for="trh in Object.keys(wagon_dislocation[0])"
                  :key="trh"
              >
                <th
                    class="bg-light text-center"
                    :style="{ 'min-width': trh === 'wagon' ? '150px' : 'auto' }"
                >
                    <span>
                      {{
                        trh
                            .split("_")
                            .map(
                                (word) =>
                                    word.charAt(0).toUpperCase() + word.slice(1)
                            )
                            .join(" ")
                      }}
                    </span>
                </th>
              </template>
            </tr>
            </thead>
            <tbody>
            <tr
                class="align-middle"
                v-for="data in wagon_dislocation"
                :key="data.index"
            >
              <td
                  class="text-center"
                  v-for="trh in Object.keys(wagon_dislocation[0])"
                  :key="trh"
              >
                <div style="max-width: 100px" class="mx-auto">
                  <VTooltip>
                    <h6 class="text-truncate mb-0 fw-normal">{{ data[trh] }}</h6>
                    <template v-slot:popper>
                      <span>{{ data[trh] }}</span>
                    </template>
                  </VTooltip>
                </div>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </SimpleBar>
      <div class="text-end">
        <b-button
            v-if="!loading_step2"
            @click="confirmExcel()"
            variant="success"
        >Confirm and Submit
        </b-button
        >
        <b-button v-else disabled variant="success">Saving..</b-button>
      </div>
    </b-card>
  </div>
</template>

<style scoped></style>
