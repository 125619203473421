<script>
import axios from "axios"
import SingleWagonDislocationsList from "@/views/pages/trains/details/components/SingleWagonDislocations.vue";

export default {
  name: "SingleWagonDislocations",
  components: {
    SingleWagonDislocationsList,
  },
  data() {
    return {
      dislocations: [],
      wagon: null,
      showModal: false
    }
  },
  methods: {
    async getWagonDislocation() {
      try {
        let response = await axios.get(`/train/dislocation/wagon/${this.$route.params.train_slug}/${this.wagon.name}/`)
        this.dislocations = response.data.results
      } catch {
        alert("Error")
      }
    },
    openModal(wagon) {
      this.wagon = wagon
      this.showModal = true
    }
  },
  watch: {
    wagon: {
      handler: function () {
        this.getWagonDislocation()
      },
      deep: true
    }
  }
}
</script>

<template>

  <b-modal v-model="showModal" title="Train Dislocation" centered hide-footer size="xl">
    <SingleWagonDislocationsList :wagon="wagon" v-if="wagon && wagon.name"/>
  </b-modal>

</template>

<style scoped>

</style>