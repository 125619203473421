<template>
  <b-card>

    <div v-if="!is_loading" class="acitivity-timeline acitivity-main">
      <template v-if="journeyList.length > 0">
        <label class="mb-4 form-label fs-4">Dislocations List ({{ journeyList.length }})</label>
        <SimpleBar data-simplebar style="max-height: 600px" class="pe-0">
          <div
              v-for="item in journeyList"
              :key="item"
              class="acitivity-item d-flex pb-3 cursor-pointer"
              @mouseenter="hovered_dislocation = item"
              @mouseleave="hovered_dislocation = {}"
              v-b-tooltip.hover
              title="Click to delete"
              data-bs-toggle="modal"
              data-bs-target="#DeleteDislocationModal"
              @click="selected_dislocation = item"
          >
            <div class="flex-shrink-0 acitivity-avatar">
              <div
                  class="avatar-title rounded-circle"
                  :class="{
                'bg-soft-success text-success': hovered_dislocation.id !== item.id,
                'bg-danger text-light': hovered_dislocation.id === item.id,
              }"
              >
                <i :class="{
                  'ri-map-pin-time-line':  hovered_dislocation.id !== item.id,
                  'bx bx-trash-alt':  hovered_dislocation.id === item.id,
                }"></i>
              </div>
            </div>
            <div class="flex-grow-1 ms-3">
              <h6 class="mb-1 lh-base" :class="{
                'text-dark': hovered_dislocation.id !== item.id,
                'text-danger': hovered_dislocation.id === item.id,
              }">
                <strong>{{ item.operation_station_name }}</strong>
                -
                <strong>{{ item.distance }} km</strong>
              </h6>
              <p class="text-muted mb-1">
                {{ item.operation }}
              </p>
              <small class="mb-0 text-muted fw-semibold mb-4">
                {{ formatDate(item.operation_date) }}
              </small>
            </div>
          </div>
        </SimpleBar>
      </template>
      <template v-else>
        <NoData>No Dislocation Found</NoData>
      </template>
    </div>

    <div v-else class="text-center py-5">
      <b-spinner variant="primary"></b-spinner>
      <h5 class="mb-0">
        <label class="d-block form-label mt-3 mb-0">Loading..</label>
      </h5>
    </div>

    <!-- SHOW ALL BUTTON -->
    <div class="d-flex justify-content-center text-center pt-2" v-if="limit < 100">
      <b-button variant="outline-primary" class="w-100" @click="getDislocationList(0, 100)">
        Show All
      </b-button>
    </div>

    <!-- PAGINATION STARTS HERE -->
    <div class="d-flex justify-content-center pt-3" v-if="limit >= 100 && data_count > 100">
      <nav aria-label="Page navigation example">
        <ul class="pagination mb-0">
          <li class="page-item"
              :class="{'disabled' : current_page === 1}"
              @click="goPage((current_page - 1) * limit - 100, limit, current_page - 1)"
          >
            <a class="page-link" aria-label="Previous">
              <i class="mdi mdi-chevron-left"></i>
            </a>
          </li>
          <li class="page-item cursor-pointer"
              v-for="page in pages"
              :key="`page_${page}_index`"
              :class="{'active' : page === current_page}"
              @click="goPage((page - 1) * limit, limit, page)"
          >
            <a class="page-link">
              {{ page }}
            </a>
          </li>
          <li class="page-item"
              :class="{'disabled' : current_page === pages}"
              @click="goPage((current_page + 1) * limit - 100, limit, current_page + 1)"
          >
            <a class="page-link" aria-label="Next">
              <i class="mdi mdi-chevron-right"></i>
            </a>
          </li>
        </ul>
      </nav>
    </div>
  </b-card>

  <DeleteConfirmation
      id="DeleteDislocationModal"
      header="Delete Dislocation"
      confirmationPlaceholder="YES"
      @onDelete="deleteDislocation()"
  >
    <template #recordName>
      Type YES to confirm
    </template>
  </DeleteConfirmation>
</template>

<script>
import axios from "axios";
import NoData from "@/components/custom/NoData.vue";

import moment from "moment";
import {SimpleBar} from "simplebar-vue3";
import DeleteConfirmation from "@/components/forms/DeleteConfirmation.vue";
import Swal from "sweetalert2";

export default {
  data() {
    return {
      journeyList: [],
      is_loading: true,
      data_count: 0,
      offset: 0,
      limit: 5,
      current_page: 1,

      hovered_dislocation: {},
      selected_dislocation: {},
    };
  },
  computed: {
    pages() {
      return Math.ceil(this.data_count / this.limit);
    }
  },
  components: {
    NoData,
    SimpleBar,
    DeleteConfirmation
  },
  methods: {
    async getDislocationList(offset, limit) {
      if (offset || offset === 0) this.offset = offset;
      if (limit) this.limit = limit;

      try {
        this.is_loading = true;
        let response = await axios.get(
            `/train/dislocation/${this.$route.params.train_slug}/`, {
              params: {
                limit: this.limit,
                offset: this.offset
              }
            }
        );
        this.data_count = response.data.count;
        this.journeyList = response.data.results;
      } catch {
        console.log("error");
      }
      this.is_loading = false;
    },
    async goPage(offset, limit, page) {
      if (page < 1 || page > this.pages) return;
      this.current_page = page;
      await this.getDislocationList(offset, limit);
    },
    formatDate(date) {
      if (date) return moment(date).format('lll');
      return '-'
    },


    async deleteDislocation() {
      if (!this.selected_dislocation && !this.selected_dislocation.id) {
        alert("No dislocation was selected")
        return
      }
      try {
        await axios.delete(`/train/dislocation/delete/${this.selected_dislocation.id}/`)
        document.querySelector('#DeleteDislocationModal .btn-close').click()
        await this.getDislocationList()
        await Swal.fire({
          icon: 'success',
          title: 'Dislocation deleted successfully',
          showConfirmButton: false,
          timer: 2000
        })
      } catch {
        await Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Something went wrong!',
        })
      }
    }
  },
  created() {
    this.getDislocationList();
  },
};
</script>
