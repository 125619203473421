<template>
  <SingleWagonDislocations
      ref="singleWagonDislocations"
      :wagon="current_wagon_for_dislocation"
      @deleteDislocation="this.$emit('deleteDislocation', $event)"
  />

  <SingleWagonComments
      :wagon="current_wagon_for_comment"
  />

  <b-row>
    <b-col>
      <b-card v-if="is_loading" class="text-center py-5">
        <b-spinner label="Loading..."></b-spinner>
        <h5 class="mb-0 mt-3">Loading ...</h5>
      </b-card>
      <template v-else-if="!is_loading">
        <template v-if="company_containers.length > 0">
          <b-card>
            <div class="flex-shrink-0 mb-4">
              <ul class="nav nav-pills card-header-pills" role="tablist">
                <li
                    class="nav-item"
                    role="presentation"
                    v-for="(company, index) in company_containers"
                    :key="`company_tab_li_${company.id}`"
                >
                  <a
                      class="nav-link"
                      :class="{ active: index === 0 }"
                      :href="`#company-${company.company.slug}`"
                      data-bs-toggle="tab"
                      role="tab"
                      aria-selected="false"
                      tabindex="-1"
                  >
                    {{ company.company?.name || "Unknown Company" }}
                  </a>
                </li>
              </ul>
            </div>

            <div class="tab-content">
              <div
                  v-for="(company, index) in company_containers"
                  :key="`company_tab_context_${company.id}`"
                  class="tab-pane"
                  :class="{ 'active show': index === 0 }"
                  :id="`company-${company.company.slug}`"
                  role="tabpanel"
              >
                <b-row>
                  <b-col md="4">
                    <div class="d-flex flex-column gap-3 bg-light p-3 mb-4">
                      <div class="d-flex align-items-top gap-2">
                        <i class="ri-home-2-line h5 mb-1"></i>
                        <router-link
                            :to="{name: 'customer_profile', params: {slug: company.company.slug}}">
                          <h5 class="mb-0 link-primary">
                            {{ company.company.name }}
                            <span class="d-inline-block mb-0" style="transform: rotate(110deg) !important;">
                              <i class="mdi mdi-reply"></i>
                            </span>
                          </h5>
                        </router-link>
                      </div>
                      <div class="d-flex align-items-top gap-2">
                        <i class="mdi mdi-email-variant h5"></i>
                        <span v-if="company.company.email">
                          {{ company.company.email }}
                        </span>
                        <span v-else class="text-muted ps-1">--</span>
                      </div>
                      <div class="d-flex align-items-top gap-2">
                        <i class="mdi mdi-phone h5"></i>
                        <span v-if="company.company.phone">
                          {{ company.company.phone }}
                        </span>
                        <span v-else class="text-muted ps-1">--</span>
                      </div>
                      <div class="d-flex align-items-top gap-2">
                        <i class="ri-map-pin-fill h5"></i>
                        <span v-if="company.company.address" class="fs-5">
                          {{ company.company.address }}
                        </span>
                        <span v-else class="text-muted ps-1">--</span>
                      </div>
                      <div class="d-flex align-items-top gap-2">
                        <i class="ri-bookmark-2-fill align-top h5 mb-0"></i>
                        <span v-if="company.company.about" class="fs-5">
                          {{ company.company.about }}
                        </span>
                        <span v-else class="text-muted ps-1">--</span>
                      </div>
                    </div>

                    <div>
                      <h5>Users List</h5>
                      <b-list-group v-if="company.users && company.users.length > 0">
                        <b-list-group-item
                            v-for="user in company.users"
                            :key="`user_${user.id}`"
                            disabled
                            aria-disabled="true"
                        >
                          <div class="d-flex align-items-center">
                            <div class="flex-shrink-0">
                              <div>
                                <a
                                    href="/accounts/counterparty/taewoong-logistics-co-ltd/"
                                >
                                  <div
                                      class="d-flex align-items-center justify-content-center"
                                  >
                                    <div class="flex-shrink-0 avatar-xs me-2">
                                      <div
                                          class="avatar-title rounded-circle bg-soft-secondary text-secondary"
                                      >
                                        <span class="fs-18">{{
                                            user.username?.charAt(0) || "U"
                                          }}</span>
                                      </div>
                                    </div>
                                    <div
                                        class="d-inline-block text-truncate"
                                        style="max-width: 150px"
                                    >
                                      <h6 class="mb-0">
                                        {{ user.first_name }}
                                      </h6>
                                      <router-link :to="{name: 'user_profile', params: {slug: 1}}">
                                        <span>{{ user.email }}</span>
                                      </router-link>
                                    </div>
                                  </div>
                                </a
                                >
                              </div>
                            </div>
                          </div>
                        </b-list-group-item>
                      </b-list-group>
                      <div v-else>
                        <NoData>
                          <div class="mb-2 text-primary">
                            <router-link
                                :to="{name: 'customer_profile', params: {slug: company.company.slug}}">
                              {{ company.company.name }}
                              <span class="d-inline-block mb-0" style="transform: rotate(110deg) !important;">
                              <i class="mdi mdi-reply"></i>
                            </span>
                            </router-link>
                          </div>
                          has no users
                        </NoData>
                      </div>
                    </div>
                  </b-col>
                  <b-col md="8">
                    <SimpleBar data-simplebar style="max-height: 900px">
                      <div class="table-responsive">
                        <table class="table table-nowrap mb-0">
                          <thead>
                          <tr class="align-middle">
                            <th scope="col">#</th>
                            <th scope="col">Container Number</th>
                            <th scope="col">Wagon Number</th>
                            <th scope="col">Current Station</th>
                            <th scope="col">Status</th>
                            <th scope="col">
                              <i class="mdi mdi-dock-right fs-5"></i>
                            </th>
                          </tr>
                          </thead>
                          <tbody>
                          <tr
                              class="align-middle"
                              v-for="(container, index) in company.containers"
                              :key="`container_${container.id}`"
                          >
                            <th scope="row">{{ index + 1 }}</th>
                            <td>
                              <VTooltip>
                                <div class="form-check">
                                  <input class="form-check-input" type="checkbox"
                                         :id="`formCheck${container.container?.name || index}`"
                                         @change="onTrainContainerSelected(container)"
                                         v-model="container.selected_to_dispatch"
                                  >
                                  <label class="form-check-label ms-2"
                                         :for="`formCheck${container.container?.name || index}`">
                                    {{ container.container?.name || "-" }}
                                  </label>
                                </div>
                                <template v-slot:popper>
                                  Container Name
                                </template>
                              </VTooltip>
                            </td>
                            <td>
                              <VTooltip>
                                <span>{{ container.train_wagon?.wagon?.name || "-" }}</span>
                                <template v-slot:popper>
                                  Wagon Name
                                </template>
                              </VTooltip>
                            </td>
                            <td v-if="container.train_wagon.current_station">
                              <VTooltip>
                                <div class="text-truncate" style="max-width: 150px">
                                  {{ container.train_wagon.current_station.name || '--' }}
                                  ({{ container.train_wagon.current_station.code || '--' }})
                                </div>
                                <template v-slot:popper>
                                  <span class="fw-semibold d-block mb-2">
                                    {{ container.train_wagon.current_station.name || '--' }}
                                  ({{ container.train_wagon.current_station.code || '--' }})
                                  </span>
                                  Current Station
                                </template>
                              </VTooltip>
                            </td>
                            <td>
                              <VTooltip>
                                <span
                                    class="badge bg-primary-subtle text-primary"
                                >{{ container.container_status || "-" }}</span
                                >
                                <template v-slot:popper>
                                  Status
                                </template>
                              </VTooltip>
                            </td>
                            <td>
                              <div class="d-flex gap-2">
                                <i @click="showWagonComments(container.train_wagon?.wagon)"
                                   data-bs-toggle="offcanvas"
                                   data-bs-target="#SingleWagonCommentsCanvas"
                                   class="mdi mdi-comment-text-outline c_icon_hoverable fs-5 mb-0"></i>
                                <i @click="showWagonDislocation(container.train_wagon?.wagon)"
                                   class="mdi mdi-dock-right c_icon_hoverable fs-5 mb-0"></i>
                              </div>
                            </td>
                          </tr>
                          </tbody>
                        </table>
                      </div>
                    </SimpleBar>
                  </b-col>
                </b-row>
              </div>
            </div>
          </b-card>
        </template>
      </template>
    </b-col>
  </b-row>
</template>

<script>
import axios from "axios";
import {SimpleBar} from "simplebar-vue3";
import NoData from "@/components/custom/NoData.vue";
import SingleWagonDislocations from "../modals/SingleWagonDislocations.vue";
import SingleWagonComments from "../modals/SingleWagonComments.vue";

export default {
  emits: ['deleteDislocation', 'onTrainContainerSelected'],
  data() {
    return {
      company_containers: [],
      is_loading: true,
      current_wagon_for_dislocation: {},
      current_wagon_for_comment: {},
    };
  },
  components: {
    SimpleBar, NoData,
    SingleWagonDislocations,
    SingleWagonComments
  },
  methods: {
    async getCompanyContainers() {
      try {
        this.is_loading = true;
        let response = await axios.get(
            `/terminal/containers/${this.$route.params.train_slug}/group_by_company/`
        );
        this.company_containers = response.data.results
        this.company_containers.forEach(company => {
          company.containers.forEach(container => {
            container.selected_to_dispatch = false
          })
        })
      } catch {
        console.log("error");
      }
      this.is_loading = false;
    },
    showWagonDislocation(wagon) {
      if (!wagon) return alert("Cannot show this wagon's dislocation !")
      this.$refs.singleWagonDislocations.openModal(wagon)
    },
    showWagonComments(wagon) {
      if (!wagon) return alert("Cannot show this wagon's comments !")
      this.current_wagon_for_comment = wagon;
    },
    onTrainContainerSelected(container) {
      this.$emit('onTrainContainerSelected', container)
    }
  },
  created() {
    this.getCompanyContainers();
  },
};
</script>
