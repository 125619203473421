<template>
  <div class="row">
    <div class="col-9">
      <div class="mb-4">
        <UploadJourney @update="this.$refs.DislocationList.getDislocationList()"/>
      </div>
      <WagonContainers @onTrainContainerSelected="onTrainContainerSelected"/>
    </div>
    <div class="col-3">
      <SendEmail :selected_train_containers="selected_train_containers"/>
      <DislocationList ref="DislocationList"/>
    </div>
  </div>
</template>

<script>
import UploadJourney from "@/views/pages/trains/details/components/UploadJourney.vue";
import WagonContainers from "@/views/pages/trains/details/components/WagonContainers.vue";
import DislocationList from "@/views/pages/trains/details/components/DislocationList.vue";
import SendEmail from "@/views/pages/trains/details/components/SendEmail.vue";

export default {
  name: "containers_tab",
  data() {
    return {
      step: "list",
      selected_train_containers: []
    };
  },
  components: {
    UploadJourney,
    WagonContainers,
    DislocationList,
    SendEmail
  },
  methods: {
    onTrainContainerSelected(container) {
      if (this.selected_train_containers.map(container => container.id).includes(container.id)) {
        this.selected_train_containers = this.selected_train_containers.filter(
          c => c.id !== container.id
        );
      } else {
        this.selected_train_containers.push(container);
      }
    }
  }
};
</script>

<style scoped></style>
